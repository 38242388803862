@font-face {
  font-family: 'Vtks good luck for you';
  src: url('../fonts/Vtks good luck for you.woff') format('woff'),
    url('../fonts/Vtks good luck for you.ttf') format('truetype');
}

@font-face {
  font-family: 'crust_clean';
  font-style: normal;
  font-weight: normal;
  src: local('crust_clean'), url('../fonts/crustype_clean.woff') format('woff'),
    url('../fonts/crustype_clean.ttf') format('truetype');
}

@font-face {
  font-family: 'chaparral pro';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/ChaparralPro-Regular.otf') format('opentype')
}

@font-face {
  font-family: 'chaparral pro';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/ChaparralPro-Bold.otf') format('opentype')
}

@font-face {
  font-family: 'chaparral pro';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/ChaparralPro-Italic.otf') format('opentype')
}

@font-face {
  font-family: 'chaparral pro';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/ChaparralPro-BoldIt.otf') format('opentype')
}
