.buttons-container-enter {
  transform: translateY(40vh);
  bottom: calc(4vh + 2px);
  position: absolute;
}
.buttons-container-enter-active {
  transform: translateY(calc(4vh + 2px));
  transition: transform 500ms;
  transition-delay: 500ms;
}
.buttons-container-exit {
  transform: translateY(calc(4vh + 2px));
  bottom: calc(4vh + 2px);
  position: absolute;
}
.buttons-container-exit-active {
  transform: translateY(40vh);
  transition: transform 500ms;
}

.buttons-container-appear {
  transform: translateY(25vh);
  bottom: calc(4vh + 2px);
  position: absolute;
}
.buttons-container-appear-active {
  transform: translateY(calc(4vh + 2px));
  transition: transform 500ms;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.25s;
}
.fadeOutSlow {
  opacity: 0;
  transition: opacity 2s;
}
.fadeInSlow {
  opacity: 1;
  transition: opacity 2s;
}
